<template>
  <div class="cal-event-detail template-3">
    <g-background>
      <page-body>
        <section class="section-1">
          <header class="mb-2">
            <h1>{{ meetingTitle }}</h1>
            <h5 v-html="eventName"></h5>
          </header>
          <div class="d-flex row details">
            <div class="col">
              <h2 class="h-style-1 mb-2">{{ meetingFrequency }}</h2>
              <div class="detail">
                <iconContent :obj="meetDate" />
                <iconContent :obj="meetLocation" />
              </div>
            </div>
            <div class="col">
              <h2 class="h-style-1 mb-2">{{ translations.tcCoordinator }}</h2>
              <div class="detail">
                <p>
                  <strong>{{ coordinator }}</strong>
                </p>
                <p>
                  <i>{{ coordinator_phone }}</i>
                  <br />
                  <a href="mailto:"
                    ><i>{{ coordinator_email }}</i></a
                  >
                  <br />{{ translations.tcMemberType }}:
                  <span class="gideon">{{ memberType }}</span>
                </p>
              </div>
            </div>
            <div class="col">
              <h2 class="h-style-1 mb-2">{{ translations.tcDistribution }}</h2>
              <div class="detail">
                <div class="col" v-if="!!meetingSelected.distribution">
                  <ul v-for="item in selectedAreas" :key="item.value">
                    <li>{{ item.text }}</li>
                  </ul>
                </div>
                <div v-else class="col">
                  <ul>
                    <li>{{ translations.tcFullstate }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section class="form-buttons">
            <b-button v-if="accessible_edit" variant="primary" class="ml-4" @click="meetingEdit">
              {{ translations.tcEdit }}
            </b-button>
            <b-button v-if="accessible_delete" variant="primary" class="ml-4" @click="meetingSoftDelete">
              {{ translations.tcDelete }}
            </b-button>
            <b-button variant="primary" class="ml-4" @click="goback">{{ translations.tcCancel }}</b-button>
          </section>
        </section>
      </page-body>
    </g-background>
    <v-dialog />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AddMeetingToEmail from '@/components/AddMeetingToEmail.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import calendarService from '../../../services/calendarService'
import gBackground from '@/components/gBackground.vue'
import iconContent from '@/components/iconContent.vue'
import { Loader } from '@/mixins/Loader'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'state-event-detail',
  mixins: [calendarMixin, Loader, securityMixin, translationMixin],
  props: {
    data: {
      default: null,
      type: Object,
    },
    modal: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      translations: {},
      selectedAreas: [],
      delete_accessible: false,
      edit_accessible: false,
      state_delete_accessible: false,
      state_edit_accessible: false,
      secured_controls: {
        calendar_delete_event_button: 'f7854ee5-6797-430e-baa4-daa3d5ad2913',
        calendar_edit_event_button: '77656905-18c2-436f-b722-2650cd199a20',
        state_calendar_edit_event_button: '31fa3072-0fd6-4aab-bf89-63ea1f75e752',
        state_calendar_delete_event_button: '9ba0ac7e-337b-4dda-a699-6a3dad091090',
      },
      optionsStateRegion: [],
      optionsStateArea: [],
      mgt_key: null,
      mtr_key: null,
      id: null,
      meeting_obj: null,
      show: false,
      meetDate: {
        img: {
          src: 'svgs/iCalendar.svg',
          width: 22,
          height: 22,
        },
        content: '',
      },
      meetLocation: {
        img: {
          src: 'svgs/iMapPoint.svg',
          width: 18,
          height: 29,
        },
        content: '',
      },
      coordinator: null,
      coordinator_email: null,
      coordinator_phone: null,
      eventName: null,
      meetingFreq: null,
      meetingFrequency: null,
      meetingTitle: null,
      memberType: null,
      title: '',
    }
  },
  methods: {
    ...mapActions({
      getAreas: 'eventCalendar/getAreas',
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      setEditType: 'eventCalendar/setEditType',
      setLoadingStatus: 'menu/setLoadingStatus',
      setUser: 'user/setUser',
    }),
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.delete_accessible = this.determineAccessibility(this.secured_controls.calendar_delete_event_button)
        this.edit_accessible = this.determineAccessibility(this.secured_controls.calendar_edit_event_button)
        this.state_edit_accessible = this.determineAccessibility(this.secured_controls.state_calendar_edit_event_button)
        this.state_delete_accessible = this.determineAccessibility(
          this.secured_controls.state_calendar_delete_event_button
        )
      })
    },
    async getOrgAreas(t_parent_org_key, org_key, lng_key) {
      this.showLoader()
      await calendarService
        .GetOrganisationHierarchy(t_parent_org_key, org_key, lng_key)
        .then((res) => {
          this.optionsStateRegion = res.data.sub_areas.map((item) => {
            return { text: item.org_name, value: item.t_org_key }
          })
          let combinevalues = []
          res.data.sub_areas.map((item) => {
            combinevalues = [...combinevalues, ...item.sub_areas]
          })

          this.optionsStateRegion.sort((a, b) => {
            var textA = a.text.toUpperCase()
            var textB = b.text.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
          })

          this.optionsStateArea = combinevalues.map((item) => {
            return {
              text: item.org_name,
              value: item.org_key,
              org_key: item.region_key,
              camps: item.camps,
            }
          })
          this.optionsStateArea.sort((a, b) => {
            var textA = a.text.toUpperCase()
            var textB = b.text.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
          })
          this.getAreas(this.optionsStateArea).then((res) => {})
          this.tempOptionsStateArea = this.optionsStateArea
        })
        .catch((err) => {
          console.error('Error', err)
        })
        .finally(() => {
          this.closeLoader()
        })
    },
    goback() {
      this.$router.push({ path: `/calendar/state/event/state-event-view` })
    },
    meetingSoftDelete() {
      let button = []
      let title = this.translations.tcAreYouSure
      if (!!this.meeting_obj.reccurrencetype && this.meeting_obj.reccurrencetype.vmrt_trn_txt !== 'Once') {
        title = this.translations.tcRecurringMeetingEdit
        button = [
          {
            title: this.translations.tcDeleteRecurrence,
            handler: () => {
              this.showLoader()
              calendarService
                .DeleteStateMeeting(this.meeting_obj.reccurrencetype.mtr_key, true) //TODO: get user name from store or sesssion
                .then((res) => {
                  if (res.status === 200) {
                    this.$swal({
                      icon: 'success',
                      text: this.translations.tcDeletionSuccessful,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      confirmButtonText: this.translations.tcOk || 'Ok',
                    }).then((result) => {
                      if (result) {
                        this.$router.push({
                          path: `/calendar/state/event/state-event-view`,
                        })
                      }
                    })
                    this.$parent.$emit('close')
                    this.$modal.hide('dialog')
                  }
                })
                .catch((err) => {})
                .finally(() => {
                  this.closeLoader()
                })
            },
          },
        ]
      }
      this.$modal.show('dialog', {
        title: this.translations.tcAlert,
        text: title,
        buttons: [
          {
            title: this.translations.tcNo, //MeetingDeleteByRecurrence
            handler: () => {
              this.$modal.hide('dialog')
            },
          },
          {
            title: this.translations.tcDeleteMeeting, // Button title
            default: true, // Will be triggered by default if 'Enter' pressed.
            handler: () => {
              this.showLoader()
              calendarService
                .DeleteStateMeeting(this.meeting_obj.id, false) //TODO: get user name from store or sesssion
                .then((res) => {
                  if (res.status === 200) {
                    this.$swal({
                      icon: 'success',
                      text: this.translations.tcDeletionSuccessful,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      confirmButtonText: this.translations.tcOk || 'Ok',
                    }).then((result) => {
                      if (result) {
                        this.$router.push({
                          path: `/calendar/state/event/state-event-view`,
                        })
                      }
                    })
                  } else {
                    //display error message :TODO
                    this.$swal({
                      icon: 'error',
                      text: this.translations.tcErrorSomethingHappened,
                      confirmButtonText: this.translations.tcOk || 'Ok',
                    })
                  }
                })
                .catch((err) => {
                  //display error message :TODO
                  this.$swal({
                    icon: 'error',
                    text: this.translations.tcErrorInternetLost,
                    confirmButtonText: this.translations.tcOk || 'Ok',
                  })
                })
                .finally(() => {
                  this.closeLoader()
                })
            }, // Button click handler
          },
          ...button,
        ],
      })
    },
    meetingEdit() {
      let button = []
      let title = this.translations.tcAreYouSure
      if (!!this.meeting_obj.reccurrencetype && this.meeting_obj.reccurrencetype.vmrt_trn_txt !== 'Once') {
        title = this.translations.tcRecurringMeetingEdit
        button = [
          {
            title: this.translations.tcEditRecurrence,
            handler: async () => {
              this.$modal.hide('dialog')
              this.$parent.$emit('close')
              this.setEditType({ type: 'multiple' })
              this.$router.push({
                path: `/calendar/state/event/geographic-dist`,
              })
            },
          },
        ]
      }
      this.$modal.show('dialog', {
        title: this.translations.tcAlert,
        text: title,
        buttons: [
          {
            title: this.translations.tcNo,
            handler: () => {
              this.$modal.hide('dialog')
            },
          },
          {
            title: this.translations.tcEdit, // Button title
            default: true, // Will be triggered by default if 'Enter' pressed.
            handler: async () => {
              this.$modal.hide('dialog')
              this.$parent.$emit('close')
              await this.setEditType({ type: 'single' })
              this.$router.push({
                path: `/calendar/state/event/geographic-dist`,
              })
            }, // Button click handler
          },
          ...button,
        ],
      })
    },
    addMeeting() {
      // See `EventDetail.vue` if this ends up being used
      let cmp = {
        template: `<add-meeting-to-email :meeting='objSelected'></add-meeting-to-email>`,
        components: {
          'add-meeting-to-email': AddMeetingToEmail,
        },
        props: ['objSelected'],
      }
      let data = {
        objSelected: this.meeting_obj,
      }
      let style = {
        height: 'auto',
        scrollable: true,
        draggable: true,
        transition: 'overlay-fade',
      }
      this.$modal.show(cmp, data, style)
    },
    generateLocationTextBlock() {
      if (!this.meeting_obj) return false

      const hostName = !!this.meeting_obj.hostname ? this.meeting_obj.hostname : ''
      const orgName = !!this.meeting_obj.location.org_name ? this.meeting_obj.location.org_name : ''
      const location = !!this.meeting_obj.location ? this.meeting_obj.location : ''
      let locationVirtual = ''
      if (this.meeting_obj.locationVirtual.lvt_key) {
        locationVirtual += `${this.meeting_obj.locationVirtual.lvt_name}`
        locationVirtual += `<br/><a href="${this.meeting_obj.locationVirtual.lvt_url}" target="_blank">${this.meeting_obj.locationVirtual.lvt_url}</a>`
        locationVirtual += this.meeting_obj.locationVirtual.lvt_note ? `<br/>${this.meeting_obj.locationVirtual.lvt_note}` : ''
      }
      const adrLine1 = !!this.meeting_obj.location.adr_line1 ? this.meeting_obj.location.adr_line1 : ''
      const adrCity = !!this.meeting_obj.location.adr_city ? this.meeting_obj.location.adr_city : ''
      const adrState = !!this.meeting_obj.location.adr_state ? this.meeting_obj.location.adr_state : ''
      const adrPostal = !!this.meeting_obj.location.adr_postal_code ? this.meeting_obj.location.adr_postal_code : ''

      let tempString = `<p><strong>${hostName}</strong><br/>`
      tempString += `${orgName || location}<br/>`
      if (!this.meeting_obj.locationVirtual.lvt_key) {
      tempString += adrCity
        ? `${adrLine1}<br/>${adrCity}, ${adrState} ${adrPostal}</p>`
        : `${adrLine1}<br/> ${adrState}</p>`
      } else {
        tempString += `${locationVirtual}`
      }

      return (this.meetLocation.content = tempString)
    },
    // Some Meeting Titles can be looked up by GUID, but some need to be translated by doing string-matching instead
    generateMeetingTitle() {
      const titleGuid = this.meeting_obj.mtg_mtt_key ? this.meeting_obj.mtg_mtt_key.toLowerCase() : ''

      if (!!titleGuid) {
        // GUID Lookup
        this.meetingTitle = this.translations.common['event-types'].hasOwnProperty(titleGuid)
          ? this.translations.common['event-types'][titleGuid]
          : this.meeting_obj.meetingtitle
      } else {
        // String-based Lookup
        const lookupVal = this.convertValForTranslation(this.meeting_obj.meetingtitle) // `tcCampMeeting`
        this.meetingTitle = this.translations.commonText['event-types'].hasOwnProperty(lookupVal)
          ? this.translations.commonText['event-types'][lookupVal]
          : this.meeting_obj.meetingtitle
      }
    },
    generateStartDate() {
      if (!this.meeting_obj) return false
      let startDate = null
      let startDateTranslated = ''
      let meetingExpression = this.meetingExpression(this.meeting_obj.reccurrencetype, this.translations)

      if (this.meeting_obj.hasOwnProperty('start') && this.meeting_obj.start) {
        startDate = this.meeting_obj.start
      } else if (this.meeting_obj.hasOwnProperty('startdate') && this.meeting_obj.startdate) {
        startDate = this.meeting_obj.startdate
      }

      if (startDate) startDateTranslated = this.returnFormattedDate(startDate) + ' | '

      this.meetDate.content = `<p>${startDateTranslated}${this.meeting_obj.startendtime}<br/>${meetingExpression}</p>`
      return true
    },
  },
  computed: {
    ...mapGetters({
      areas: 'eventCalendar/getAreas',
      demograph: 'user/demograph',
      iCanSee: 'user/iCanSee',
      getEditType: 'eventCalendar/getEditType',
      meetingSelected: 'eventCalendar/meetingSelected',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
    }),
    accessible_delete() {
      return (
        !!this.meetingSelected &&
        ((this.meetingSelected.scope === 'State' && this.state_delete_accessible) ||
        (this.meetingSelected.scope === 'Country' && this.state_delete_accessible) ||
          (this.meetingSelected.scope === 'Camp' && this.delete_accessible))
      )
    },
    accessible_edit() {
      return (
        !!this.meetingSelected &&
        ((this.meetingSelected.scope === 'State' && this.state_edit_accessible) ||
        (this.meetingSelected.scope === 'Country' && this.state_edit_accessible) ||
          (this.meetingSelected.scope === 'Camp' && this.edit_accessible))
      )
    },
  },
  components: {
    'add-meeting-to-email': AddMeetingToEmail,
    gBackground: gBackground,
    iconContent: iconContent,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
  async mounted() {
    await this.checkPermissions()
  },
  async created() {
    try {
      this.setLoadingStatus(true)

      await Promise.all([
        await this.getViewTranslations('buttons', 'date-time'),
        await this.getComponentTranslations('add-meeting-to-email'),
        await this.getComponentTranslations('common.calendar-event-type', 'common.calendar-event-type-state'),
        await this.getOrgAreas(this.demograph.state_key, this.demograph.camp_key, this.demograph.language_key),
      ]).then((results) => {
        this.stripReadableText(results[2], 'text')
        this.translations.common['event-types'] = {
          // merge event types together (for GUID lookups)
          ...this.translations.common['calendar-event-type'],
          ...this.translations.common['calendar-event-type-state'],
        }
        this.translations.commonText['event-types'] = {
          // merge event types together (for string lookups)
          ...this.translations.commonText['calendar-event-type'],
          ...this.translations.commonText['calendar-event-type-state'],
        }
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
          commonText: { ...this.translations.commonText },
        }
        this.$set(this.translations, 'components', translatedText)
      })

      if (!!this.meetingSelected.distribution) {
        this.areas.forEach((item) => {
          let selected_areas = this.meetingSelected.distribution.filter(
            (x) => x.mgd_children_org_key.toUpperCase() === item.value.toUpperCase()
          )[0]
          if (!!selected_areas) {
            if (item && item.text.includes('State Area')) {
              const regionNum = item.text.split('State Area')[1].trim()
              const stateArea = this.translations.tcStateArea
              item.text = `${stateArea} ${regionNum}`
            }
            this.selectedAreas.push(item)
          }
        })
      }

      if (!this.meetingSelected.meetingtitle) {
        this.$router.push({ path: `/calendar/state/event/state-event-view` })
      }
      this.meeting_obj = this.meetingSelected
      this.mtr_key = this.meeting_obj.mtrkey
      this.id = this.meeting_obj.id
      this.eventName = this.meeting_obj.eventname
      this.meetingFreq = !!this.meeting_obj.reccurrencetype ? this.meeting_obj.reccurrencetype.vmrt_trn_txt : ''
      this.coordinator = this.meeting_obj.vip_formal_name
      this.coordinator_phone = this.meeting_obj.vip_phone
      this.coordinator_email = this.meeting_obj.vip_email
      this.memberType = this.translations[this.convertValForTranslation(this.meeting_obj.vmpt_trn_txt)]
      let startDate = this.returnFormattedDate(this.meeting_obj.startdate)
      startDate = !!startDate ? startDate : this.returnFormattedDate(this.meeting_obj.start)
      this.meetDate.content = `<p>${startDate} | ${this.meeting_obj.startendtime}<br/>
      ${this.meetingExpression(this.meeting_obj.reccurrencetype, this.translations)}</p>`
      this.meetingFrequency = await this.meetingFrequencyText(this.meeting_obj.reccurrencetype, this.translations)
      this.generateLocationTextBlock() // generates location text string, which may contain falsy values
      this.generateMeetingTitle() // looks up translated Meeting Title by GUID or using string-matching
      this.generateStartDate() // generates Start Date string and meeting recurrence string
    } catch (error) {
      console.error('Error in created()', error)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.cal-event-detail {
  margin-top: 30px;
  .page-body {
    max-width: 1160px;
  }
  .camp-select {
    margin-bottom: 60px;
  }
  h1 {
    margin-bottom: 10px;
  }
  .col {
    width: auto;
    flex: 0 1 auto;
    margin-bottom: 30px;
    margin-right: 100px;
    @include breakpoint(sm) {
      width: 100%;
      margin-right: 0;
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
  .gideon {
    color: $primary-color;
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px;
        margin-left: 0 !important;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
